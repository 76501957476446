<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        custodyExpenses.custodyExpensesData != undefined &&
        custodyExpenses.custodyExpensesData.length != 0
      "
    >
      <CustodyExpensesTable
        v-on:setCustodyExpense="custodyExpenses.custodyExpense.fillData($event)"
        :custodyExpenses="custodyExpenses.custodyExpensesData"
        :pagination="custodyExpenses.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="custodyExpenses.pagination"
        v-on:changePage="changePage"
      />
      <CustodyExpenseInfo :custodyExpense="custodyExpenses.custodyExpense" />
      <CustodyExpenseDelete
        :custodyExpense="custodyExpenses.custodyExpense"
        v-on:refresh="getAllCustodyExpenses()"
      />
    </div>

    <CustodyExpenseBtns
      :theFilterData="custodyExpenses.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustodyExpensesTable from "@/components/settings/settingsOther/custodyExpenses/CustodyExpensesTable.vue";
import CustodyExpenseInfo from "@/components/settings/settingsOther/custodyExpenses/CustodyExpenseInfo.vue";
import CustodyExpenseDelete from "@/components/settings/settingsOther/custodyExpenses/CustodyExpenseDelete.vue";
import CustodyExpenseBtns from "@/components/settings/settingsOther/custodyExpenses/CustodyExpenseBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import CustodyExpenses from "@/models/settings/settingsOther/custodyExpenses/CustodyExpenses";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustodyExpensesTable,
    CustodyExpenseInfo,
    CustodyExpenseDelete,
    CustodyExpenseBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      custodyExpenses: new CustodyExpenses(),
    };
  },
  methods: {
    changePage(page) {
      this.custodyExpenses.pagination.selfPage = page;
      this.getAllCustodyExpenses();
    },
    search(data) {
      this.custodyExpenses.filterData.fillData(data);
      this.custodyExpenses.custodyExpensesData = [];
      this.getAllCustodyExpenses();
    },
    async getAllCustodyExpenses() {
      this.isLoading = true;
      try {
        const response =
          await this.custodyExpenses.custodyExpense.getAllCustodyExpenses(
            this.language,
            this.userAuthorizeToken,
            this.custodyExpenses.pagination,
            this.custodyExpenses.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.custodyExpenses.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.custodyExpenses.custodyExpensesData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.custodyExpenses.custodyExpensesData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.custodyExpenses.custodyExpensesData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.custodyExpenses.custodyExpensesData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllCustodyExpenses();
  },
};
</script>
