<template>
  <b-modal id="CustodyExpenseInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/CustodyExpenses.svg" class="icon-lg" />
        {{ $t("CustodyExpenses.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.employeeNameCurrent"
        :title="$t('CustodyExpenses.employeeName')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseNameAr"
        :title="$t('CustodyExpenses.nameAr')"
        :imgName="'CustodyExpenses.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseNameEn"
        :title="$t('CustodyExpenses.nameEn')"
        :imgName="'CustodyExpenses.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseNameUnd"
        :title="$t('CustodyExpenses.nameUnd')"
        :imgName="'CustodyExpenses.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseDescriptionAr"
        :title="$t('CustodyExpenses.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseDescriptionEn"
        :title="$t('CustodyExpenses.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseDescriptionUnd"
        :title="$t('CustodyExpenses.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpense.custodyExpenseNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["custodyExpense"],
  methods: {},
};
</script>
