<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="
          checkPrivilege(
            usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
              .additionalSettingsAdd
          )
        "
      >
        <span class="fab-label">
          <router-link :to="{ name: 'CustodyExpenseAdd' }" class="">
            {{ $t("CustodyExpenses.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'CustodyExpenseAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="CustomBottomSheet">
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />

        <CustomSelectBox
          :className="'col-md-12'"
          :id="'employeeToken'"
          :value="filterData.employeeToken"
          :options="employeeTokenOptions"
          v-on:changeValue="filterData.employeeToken = $event"
          :title="$t('selectEmployee')"
          :imgName="'employees.svg'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import privilegeMixin from "@/utils/privilege-mixin";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [privilegeMixin, createToastMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      filterData: this.theFilterData,
      employeeTokenOptions: [],
    };
  },
  components: {
    CustomInput,
    CustomSelectBox,
  },
  methods: {
    open() {
      this.$refs.CustomBottomSheet.open();
    },
    close() {
      this.$refs.CustomBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    checkPrivilege,
  },
  async created() {
    this.getDialogOfEmployees();
  },
};
</script>
